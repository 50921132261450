import api from "@app/config/api";

import IClasseEntity from "@app/features/Classe/data/IClasseEntity";
import IUserFromClasseEntity from "@app/features/Classe/data/IUserFromClasseEntity";

import { TCreateClasseRemoteData } from "@app/services/classe/remote-types/TCreateClasseRemoteData";
import { TAddUserToClasseRemoteData } from "@app/services/classe/remote-types/TAddUserToClasseRemoteData";
import { TEditClasseRemoteData } from "@app/services/classe/remote-types/TEditClasseRemoteData";
import { THandleInviteClasseRemoteData } from "@app/services/classe/remote-types/THandleInviteClasseRemoteData";

import API_SERVICES from "../constants";
import { ROLE, USER_CLASSE_STATUS } from "@app/constants/enums";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";

export type TListUsersFromClasseSearchParams = {
  name?: string;
  email?: string;
  statusClasse?: USER_CLASSE_STATUS;
  role?: string;
};

export interface IClasseService {
  listClassesService: (companyId: number) => Promise<IClasseEntity[]>;
  getClasseService: (classeId: number) => Promise<IClasseEntity>;
  editClasseService: (classeId: TEditClasseRemoteData) => Promise<void>;
  createClasseService: (data: TCreateClasseRemoteData) => Promise<void>;
  listUsersFromClasseService: (
    classeId: number,
    roles: ROLE[],
    pageConfig: IPageModel,
    filterParams?: TListUsersFromClasseSearchParams
  ) => Promise<IPaginationModel<IUserFromClasseEntity>>;
  addUserToClasseService: (data: TAddUserToClasseRemoteData) => Promise<void>;
  handleInviteClasseService: (
    data: THandleInviteClasseRemoteData
  ) => Promise<void>;
  removeUsersFromClasseService: (
    classeId: number,
    userId: number
  ) => Promise<void>;
}

const listClassesService = async (
  companyId: number
): Promise<IClasseEntity[]> =>
  api
    .get(API_SERVICES.CLASSE_SERVICES.LIST(companyId), {
      headers: { companyId },
    })
    .then((res) => res.data);

const getClasseService = async (classeId: number): Promise<IClasseEntity> =>
  api
    .get(API_SERVICES.CLASSE_SERVICES.GET_CLASSE(classeId), {
      headers: { classeId },
    })
    .then((res) => res.data);

const editClasseService = async (data: TEditClasseRemoteData): Promise<void> =>
  api
    .put(API_SERVICES.CLASSE_SERVICES.EDIT_CLASSE(data.id), data, {
      headers: { classeId: data.id },
    })
    .then((res) => res.data);

const handleInviteClasseService = async (
  data: THandleInviteClasseRemoteData
): Promise<void> =>
  api
    .post(API_SERVICES.CLASSE_SERVICES.HANDLE_INVITE(data.classeId), data, {
      headers: { classeId: data.classeId },
    })
    .then((res) => res.data);

const createClasseService = async (
  data: TCreateClasseRemoteData
): Promise<void> =>
  api
    .post(API_SERVICES.CLASSE_SERVICES.CREATE, data, {
      headers: { companyId: data.companyId },
    })
    .then((res) => res.data);

const listUsersFromClasseService = async (
  classeId: number,
  roles: ROLE[],
  pageConfig: IPageModel,
  filterParams?: TListUsersFromClasseSearchParams
): Promise<IPaginationModel<IUserFromClasseEntity>> =>
  api
    .get(API_SERVICES.CLASSE_SERVICES.GET_USERS_FROM_CLASSE(classeId), {
      headers: { roles, classeId },
      params: { ...pageConfig, ...filterParams },
    })
    .then((res) => res.data);

const removeUsersFromClasseService = async (
  classeId: number,
  userId: number
): Promise<void> =>
  api
    .delete(
      API_SERVICES.CLASSE_SERVICES.REMOVE_USERS_FROM_CLASSE(classeId, userId),
      { headers: { classeId } }
    )
    .then((res) => res.data);

const addUserToClasseService = async (
  data: TAddUserToClasseRemoteData
): Promise<void> =>
  api
    .post(API_SERVICES.CLASSE_SERVICES.ADD_USER_TO_CLASSE, data, {
      headers: { classeId: data.classeId },
    })
    .then((res) => res.data);

const classeService: IClasseService = {
  listClassesService,
  getClasseService,
  editClasseService,
  createClasseService,
  listUsersFromClasseService,
  addUserToClasseService,
  handleInviteClasseService,
  removeUsersFromClasseService,
};

export default classeService;
