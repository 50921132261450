import api from "@app/config/api";

import { TAssetPath } from "@app/constants";

import API_SERVICES from "../constants";
import { AxiosProgressEvent } from "axios";

export interface IUploadEntity {
  fileKey: string;
  url: string;
}

export interface IDeleteAssetRemoteData {
  fileKey?: string;
  url?: string;
}

export interface IUploadService {
  uploadAssetService: (
    formData: FormData,
    assetPath: TAssetPath,
    companyId?: number,
    callbackProgress?: (progressEvent: AxiosProgressEvent) => void
  ) => Promise<IUploadEntity>;
  deleteAssetService: (data: IDeleteAssetRemoteData) => Promise<void>;
}

export const deleteAssetService = async (
  data: IDeleteAssetRemoteData
): Promise<void> =>
  api
    .delete(API_SERVICES.UPLOAD_SERVICES.DELETE_ASSET, { headers: { ...data } })
    .then((res) => res.data);

export const uploadAssetService = async (
  formData: FormData,
  assetPath: TAssetPath,
  companyId?: number,
  callbackProgress?: (progressEvent: AxiosProgressEvent) => void
): Promise<IUploadEntity> =>
  api
    .post(API_SERVICES.UPLOAD_SERVICES.UPLOAD_MULTIPLE(assetPath), formData, {
      onUploadProgress: callbackProgress,
      headers: { "Content-Type": `multipart/form-data`, companyId },
    })
    .then((res) => res.data);

const uploadService: IUploadService = {
  deleteAssetService,
  uploadAssetService,
};

export default uploadService;
