import React, {
  RefObject,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import { FiUpload } from "react-icons/fi";

import FileInput, { FileInputRef } from "@app/components/atoms/FileInput";
import { TUploadPayload } from "@app/components/atoms/Upload";
import UploadItem from "@app/components/atoms/UploadMultiple/UploadItem";
import showNotification from "@app/components/molecules/Toast";

import theme from "@app/config/theme";

export type FileUploadRef = {
  clearField: () => void;
  openWindow: () => void;
  uploadFile: (fileData: File) => void;
};

export type TAccept = {
  image?: boolean;
  audio?: boolean;
  file?: boolean;
  all?: boolean;
};

type FileUploadProps = {
  ref?: RefObject<FileUploadRef>;
  multiple?: boolean;
  error?: boolean;
  errorMessage?: string;
  accept: TAccept;
  onUploadSuccess: (data: TUploadPayload) => void;
  onRemove: (index: number) => void;
};

const UploadMultiple = forwardRef<unknown, FileUploadProps>(
  (
    {
      error,
      multiple = true,
      errorMessage,
      onUploadSuccess,
      onRemove,
    }: FileUploadProps,
    ref
  ) => {
    const fileInputRef = useRef<FileInputRef>(null);

    const [files, setFiles] = useState<File[]>([]);

    const clearField = useCallback(() => {}, []);

    const handleFileInputClick = useCallback(() => {
      fileInputRef.current?.openWindow();
    }, []);

    const handleFileChange = useCallback(
      async (data: FileList) => {
        const canAddMoreFiles = files.length < 10;

        if (canAddMoreFiles) {
          Object.keys(data).forEach((fileKey) => {
            if (canAddMoreFiles) {
              const file = data[fileKey as unknown as number];
              files.push(file);
            }
          });
          setFiles([...files]);
        } else {
          showNotification("Limite de 10 arquivos.", "WARNING");
        }
      },
      [files]
    );

    const handleRemove = useCallback(
      (index: number) => {
        files.splice(index, 1);
        setFiles(files);
        onRemove(index);
      },
      [files, onRemove]
    );

    const handleUploadSuccess = useCallback(
      (data: TUploadPayload) => {
        onUploadSuccess(data);
      },
      [onUploadSuccess]
    );

    useImperativeHandle(ref, () => ({ clearField }), [clearField]);

    return (
      <>
        <div
          className="upload__image-wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              "& > :not(style)": {
                m: 1,
                width: 200,
              },
              borderColor: error
                ? theme.palette.error.light
                : theme.palette.grey[500],
              borderWidth: 2,
              borderRadius: 2,
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleFileInputClick}
          >
            <Typography
              variant="subtitle1"
              color={"text.secondary"}
              align="center"
            >
              Escolha até 10 arquivos para fazer upload de uma só vez.
            </Typography>

            <Button variant="contained" startIcon={<FiUpload />}>
              Upload
            </Button>
            <FileInput
              ref={fileInputRef}
              multiple={multiple}
              accept={{ audio: true, file: true, image: true }}
              onSelectFiles={handleFileChange}
            />
          </Box>
          {errorMessage && (
            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                flex: 1,
              }}
            >
              <Typography
                variant="body2"
                color="error"
                align="center"
                sx={{ width: 200, p: 1 }}
              >
                {errorMessage}
              </Typography>
            </Box>
          )}
        </div>
        <Grid container spacing={2} justifyContent="center">
          {files &&
            Object.keys(files).map((fileKey, index) => {
              const file = files[fileKey as unknown as number];
              return (
                <Grid
                  key={fileKey}
                  item
                  xs={12}
                  sm={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <UploadItem
                    index={index}
                    file={file}
                    onUploadSuccess={handleUploadSuccess}
                    onRemove={handleRemove}
                  />
                </Grid>
              );
            })}
        </Grid>
      </>
    );
  }
);

export default UploadMultiple;
