import api from "@app/config/api";
import ICompanyEntity from "@app/features/Company/data/ICompanyEntity";

import { TCreateCompanyRemoteData } from "@app/services/company/remote-types/TCreateCompanyRemoteData";
import { TEditCompanyRemoteData } from "@app/services/company/remote-types/TEditCompanyRemoteData";

import API_SERVICES from "../constants";
import IUserModel from "@app/features/User/domain/models/IUserModel";

export interface ICompanyService {
  deleteCompanyService: (companyId: number) => Promise<void>;
  createCompanyService: (data: TCreateCompanyRemoteData) => Promise<void>;
  editCompanyService: (data: TEditCompanyRemoteData) => Promise<void>;
  getCompanyService: (companyId: number) => Promise<ICompanyEntity>;
  getCompaniesService: () => Promise<ICompanyEntity[]>;
  listAllUsersFromCompanyService: (companyId: number) => Promise<IUserModel[]>;
}

const createCompanyService = async (
  data: TCreateCompanyRemoteData
): Promise<void> =>
  api.post(API_SERVICES.COMPANY_SERVICES.CREATE, data).then((res) => res.data);

const deleteCompanyService = async (companyId: number): Promise<void> =>
  api
    .get(API_SERVICES.COMPANY_SERVICES.DELETE(companyId), {
      headers: { companyId },
    })
    .then((res) => res.data);

const editCompanyService = async (
  data: TEditCompanyRemoteData
): Promise<void> =>
  api
    .put(API_SERVICES.COMPANY_SERVICES.EDIT(data.id), data, {
      headers: { companyId: data.id },
    })
    .then((res) => res.data);

const getCompanyService = async (companyId: number): Promise<ICompanyEntity> =>
  api
    .get(API_SERVICES.COMPANY_SERVICES.GET(companyId), {
      headers: { companyId },
    })
    .then((res) => res.data);

const getCompaniesService = async (): Promise<ICompanyEntity[]> =>
  api.get(API_SERVICES.COMPANY_SERVICES.LIST).then((res) => res.data);

const listAllUsersFromCompanyService = async (
  companyId: number
): Promise<IUserModel[]> =>
  api
    .get(API_SERVICES.COMPANY_SERVICES.LIST_ALL_USERS_FROM_COMPANY(companyId), {
      headers: { companyId },
    })
    .then((res) => res.data);

const companyService: ICompanyService = {
  deleteCompanyService,
  createCompanyService,
  editCompanyService,
  getCompanyService,
  getCompaniesService,
  listAllUsersFromCompanyService,
};

export default companyService;
