import React, { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";

import LessonForm, { TLessonForm } from "@app/features/Lesson/view/Form";
import QuestionForm from "@app/features/Question/view/Form";

import { useLessonRepository } from "@app/features/Lesson/data/lessonRepository";
import { useEditLessonViewModel } from "@app/features/Lesson/view/Edit/editLessonViewModel";

import lessonService from "@app/services/lesson";

import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";
import StandardTemplate from "@app/components/templates/Standard";
import TabPanel from "@app/components/templates/TabPanel";

import { RootState } from "@app/config/store";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditLesson = () => {
  const { id } = useParams();

  const [currentTab, setCurrentTab] = useState(0);

  const { currentClasse } = useSelector((state: RootState) => state.classe);

  const lessonRepository = useLessonRepository(lessonService);
  const { getLesson, editLesson, isLoading, lesson, questions } =
    useEditLessonViewModel(lessonRepository);

  const handleChangeCurrentTab = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: number) => {
      setCurrentTab(value);
    },
    []
  );

  const handleSaveLesson = useCallback(
    (data: TLessonForm) => {
      if (currentClasse) {
        const { id: classeId } = currentClasse;

        editLesson(classeId, {
          ...data,
          id: Number(id),
        });
      }
    },
    [id, currentClasse, editLesson]
  );

  useEffect(() => {
    if (currentClasse) {
      const { id: classeId } = currentClasse;
      getLesson(classeId, Number(id));
    }
  }, [getLesson, id, currentClasse]);

  return (
    <StandardTemplate title="Editar Aula" showBackButton>
      <LoadingLock isLoading={isLoading} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={currentTab}
          onChange={handleChangeCurrentTab}
          aria-label="basic tabs example"
        >
          <Tab label="Dados da aula" {...a11yProps(0)} />
          <Tab label="Perguntas" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={currentTab} index={0}>
          <LessonForm
            dataForm={lesson}
            onValidateSuccess={handleSaveLesson}
            footerActions={<FooterActions />}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <QuestionForm
            edit
            pageId={Number(id)}
            dataForm={{ questions }}
            onValidateSuccess={() => null}
          />
        </TabPanel>
      </Box>
    </StandardTemplate>
  );
};

export default EditLesson;
