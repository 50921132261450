import React from "react";
import {
  Card,
  CardActions,
  Button,
  Grow,
  Avatar,
  Divider,
  CardMedia,
  CardHeader,
  IconButton,
  Chip,
  Stack,
} from "@mui/material";
import Restricted from "@app/components/atoms/Restricted";

import { FiMail, FiSettings } from "react-icons/fi";
import { images } from "@app/assets";
import IClasseModel from "@app/features/Classe/domain/models/IClasseModel";
import { ROLE, USER_CLASSE_STATUS } from "@app/constants/enums";

type CardClassProps = {
  data: IClasseModel;
  onClickPrimaryButton: (data: IClasseModel) => void;
  onClickSecondaryButton: (data: IClasseModel) => void;
};

const CardClasse = ({
  data,
  onClickPrimaryButton,
  onClickSecondaryButton,
}: CardClassProps) => {
  const handlePrimaryButton = () => {
    onClickPrimaryButton(data);
  };

  const handleSecondaryButton = () => {
    onClickSecondaryButton(data);
  };

  return (
    <Grow in style={{ transformOrigin: "0 0 0" }}>
      <Card sx={{ maxWidth: 300 }}>
        <CardMedia
          sx={{ height: 257.78 }}
          image={data.image ?? images.logoBackground}
        />
        <Divider />

        <CardHeader
          avatar={<Avatar src={data.language.image} />}
          action={
            data.userClasseStatus === USER_CLASSE_STATUS.INVITED ? (
              <Chip
                label="Convite"
                sx={{ mt: 1.5 }}
                size="small"
                color="primary"
              />
            ) : (
              <Restricted to={[ROLE.OWNER, ROLE.ADMIN]}>
                <IconButton
                  aria-label="settings"
                  onClick={handleSecondaryButton}
                >
                  <FiSettings />
                </IconButton>
              </Restricted>
            )
          }
          title={data.name}
        />
        <Stack direction="row" sx={{ ml: 2 }}>
          <Chip size="small" label={data.visibility} color="secondary" />
        </Stack>

        {data.userClasseStatus !== USER_CLASSE_STATUS.INVITED && (
          <CardActions>
            <Button
              size="small"
              onClick={handlePrimaryButton}
              variant="outlined"
            >
              Selecionar Turma
            </Button>
          </CardActions>
        )}
        {data.userClasseStatus === USER_CLASSE_STATUS.INVITED && (
          <CardActions>
            <Button
              size="small"
              onClick={handlePrimaryButton}
              variant="outlined"
              startIcon={<FiMail />}
            >
              Ver convite
            </Button>
          </CardActions>
        )}
      </Card>
    </Grow>
  );
};

export default React.memo(CardClasse);
