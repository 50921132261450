import axios from "axios";
import api from "@app/config/api";

import IUserEntity from "@app/features/User/data/IUserEntity";
import IStudentProgressResultEntity from "@app/features/Student/data/IStudentProgressResultEntity";

import { TSignUpRemoteData } from "@app/services/user/remote-types/TSignUpRemoteData";
import { TVerificationCodeData } from "@app/services/user/remote-types/TVerificationCodeRemoteData";
import { TResendVerificationRemoteData } from "@app/services/user/remote-types/TResendVerificationCodeRemote";
import { TChangePasswordRemoteData } from "@app/services/user/remote-types/TChangePasswordRemoteData";
import { TUpdateUserStatusRemoteData } from "@app/services/user/remote-types/TUpdateUserStatusRemoteData";

import enviroments from "@app/config/enviroments";
import API_SERVICES from "../constants";

export interface IUserService {
  signUpService: (data: TSignUpRemoteData) => Promise<void>;
  resendEmailService: (data: TResendVerificationRemoteData) => Promise<void>;
  verificationCodeService: (data: TVerificationCodeData) => Promise<void>;
  forgotPasswordService: (data: { email: string }) => Promise<void>;
  changePasswordService: (data: TChangePasswordRemoteData) => Promise<void>;
  getUserService: (classeId: number, userId: number) => Promise<IUserEntity>;
  listUserLessonsResultService: (
    userId: number,
    classeId: number
  ) => Promise<IStudentProgressResultEntity[]>;
  updateUserStatusService: (
    data: TUpdateUserStatusRemoteData
  ) => Promise<IUserEntity>;
}

const signUpService = async (data: TSignUpRemoteData): Promise<void> =>
  api.post(API_SERVICES.USER_SERVICES.SIGN_UP, data).then((res) => res.data);

const resendEmailService = async (
  data: TResendVerificationRemoteData
): Promise<void> =>
  api
    .post(API_SERVICES.USER_SERVICES.RESEND_VERIFICATION_CODE, data)
    .then((res) => res.data);

const verificationCodeService = async (
  data: TVerificationCodeData
): Promise<void> =>
  api
    .post(API_SERVICES.USER_SERVICES.VERIFICATION_CODE, data)
    .then((res) => res.data);

const forgotPasswordService = async (data: { email: string }): Promise<void> =>
  api
    .post(API_SERVICES.USER_SERVICES.FORGOT_PASSWORD, data)
    .then((res) => res.data);

const changePasswordService = async (
  data: TChangePasswordRemoteData
): Promise<void> => {
  const { token, ...body } = data;
  return axios
    .post(
      enviroments.baseURL + API_SERVICES.USER_SERVICES.CHANGE_PASSWORD,
      body,
      {
        headers: { authorization: `Bearer ${data.token}` },
      }
    )
    .then((res) => res.data);
};

const getUserService = async (
  classeId: number,
  userId: number
): Promise<IUserEntity> =>
  api
    .get(API_SERVICES.USER_SERVICES.GET_USER(userId), { headers: { classeId } })
    .then((res) => res.data);

const listUserLessonsResultService = async (
  userId: number,
  classeId: number
): Promise<IStudentProgressResultEntity[]> =>
  api
    .get(
      API_SERVICES.USER_SERVICES.LIST_USER_LESSONS_RESULT(userId, classeId),
      { headers: { classeId } }
    )
    .then((res) => res.data);

const updateUserStatusService = async (
  data: TUpdateUserStatusRemoteData
): Promise<IUserEntity> =>
  api
    .put(API_SERVICES.USER_SERVICES.UPDATE_USER_STATUS, data, {
      headers: { classeId: data.classeId },
    })
    .then((res) => res.data);

const userService: IUserService = {
  signUpService,
  verificationCodeService,
  resendEmailService,
  changePasswordService,
  forgotPasswordService,
  updateUserStatusService,
  getUserService,
  listUserLessonsResultService,
};

export default userService;
