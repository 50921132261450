import { useCallback, useState } from "react";

import listClassesUseCase from "@app/features/Classe/domain/use-cases/listClassesUseCase";
import handleInviteClasseUseCase from "@app/features/Classe/domain/use-cases/handleInviteClasseUseCase";

import { IClasseRepository } from "@app/features/Classe/data/classeRespository";

import handleApplicationError from "@app/handlers/handleApiError";
import { INVITE_STATUS } from "@app/constants/enums";
import showNotification from "@app/components/molecules/Toast";

export type TInviteClasseViewModel = {
  classeId: number;
  inviteStatus: INVITE_STATUS;
};

const useListClassesViewModel = (repository: IClasseRepository) => {
  const [isLoading, setLoading] = useState(false);

  const listClasses = useCallback(
    async (companyId: number) => {
      try {
        setLoading(true);
        await listClassesUseCase(
          {
            listClasses: repository.listClasses,
          },
          companyId
        );
        setLoading(false);
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    },
    [repository.listClasses]
  );

  const handleInviteClasse = useCallback(
    async (data: TInviteClasseViewModel, companyId: number) => {
      try {
        setLoading(true);
        await handleInviteClasseUseCase(
          {
            handleInviteClasse: repository.handleInviteClasse,
          },
          data
        );
        setLoading(false);
        listClasses(companyId);
        showNotification(
          "Parabéns, você agora faz parte de uma nova turma",
          "SUCCESS"
        );
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    },
    [repository.handleInviteClasse, listClasses]
  );

  return { isLoading, listClasses, handleInviteClasse };
};

export { useListClassesViewModel };
