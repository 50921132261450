import React, { ReactNode } from "react";

import Typography from "@mui/material/Typography";

export type TBaseText = "h4" | "caption" | "subtitle1" | "body2" | "body1";

export type TAlign = "center" | "left" | "right" | "justify";

export type TWeight = "normal" | "600" | "700" | "800" | "900";

type BaseTextPropsInternal = {
  size: TBaseText;
  children: ReactNode;
  noWrap?: boolean;
  align?: TAlign;
  weight?: TWeight;
  color?: string;
};

export type BaseTextProps = Omit<BaseTextPropsInternal, "size">;

const BaseText = ({
  children,
  noWrap,
  size,
  align,
  weight,
  color,
}: BaseTextPropsInternal) => (
  <Typography
    variant={size}
    noWrap={noWrap}
    align={align}
    fontWeight={weight}
    color={color}
  >
    {children}
  </Typography>
);

BaseText.defaultProps = {
  align: "left",
  noWrap: false,
};

export default BaseText;
