import React, { useCallback, useEffect, useRef, useState } from "react";

import { Box, Tab, Tabs } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useUserRepository } from "@app/features/User/data/userRepository";
import { useLessonRepository } from "@app/features/Lesson/data/lessonRepository";

import { useEditStudentViewModel } from "@app/features/Student/view/Edit/editStudentViewModel";

import StudentForm, { TStudentForm } from "@app/features/Student/view/Form";

import userService from "@app/services/user";
import lessonService from "@app/services/lesson";

import DrawerResult, {
  IDrawerResultRef,
} from "@app/components/organisms/DrawerResult";
import LoadingLock from "@app/components/molecules/LoadingLock";
import StandardTemplate from "@app/components/templates/Standard";
import FooterActions from "@app/components/molecules/FooterActions";
import StudentLessonAccordion from "@app/components/organisms/StudentLessonAccordion";
import TabPanel from "@app/components/templates/TabPanel";
import { Title } from "@app/components/atoms/Text";

import { RootState } from "@app/config/store";
import { ROLE } from "@app/constants/enums";
import EmptyState from "@app/components/organisms/EmptyState";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditStudent = () => {
  const { id } = useParams();

  const { currentClasse } = useSelector((state: RootState) => state.classe);
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const [currentTab, setCurrentTab] = useState(0);
  const drawerResultRef = useRef<IDrawerResultRef>();

  const userRepository = useUserRepository(userService);
  const lessonRepository = useLessonRepository(lessonService);

  const {
    getUser,
    listUserLessonsResult,
    editUserStatus,
    getAnswerFromStudentPage,
    answers,
    isLoading,
    student,
    studentLessonsResult,
  } = useEditStudentViewModel(userRepository, lessonRepository);

  const handleChangeCurrentTab = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: number) => {
      setCurrentTab(value);
    },
    []
  );

  const handleSave = useCallback(
    (data: TStudentForm) => {
      if (currentClasse && currentCompany) {
        editUserStatus({
          classeId: currentClasse.id,
          companyId: currentCompany.id,
          roleName: ROLE.STUDENT,
          userId: Number(id),
          userStatusClasse: data.userStatusClasse,
          expireAt: data.expireAt,
        });
      }
    },
    [currentClasse, currentCompany, editUserStatus, id]
  );

  const handlePress = useCallback(
    async (studentPageId: number) => {
      if (currentClasse) {
        const { id: classeId } = currentClasse;
        await getAnswerFromStudentPage(classeId, studentPageId);
      }
    },
    [currentClasse, getAnswerFromStudentPage]
  );

  const formatDate = useCallback((date?: Date): string | undefined => {
    console.log(date);
    if (!date) {
      return undefined;
    }
    const stringDate = new Date(date);

    const day = String(stringDate.getDate()).padStart(2, "0"); // Adiciona o '0' se o número for menor que 10
    const month = String(stringDate.getMonth() + 1).padStart(2, "0"); // Janeiro é 0, então somamos 1
    const year = stringDate.getFullYear();

    const formatedDate = `${day}/${month}/${year}`;

    return formatedDate;
  }, []);

  useEffect(() => {
    if (answers?.length) {
      drawerResultRef.current?.openDrawer();
    }
  }, [answers]);

  useEffect(() => {
    if (id) {
      getUser(Number(id));
    }
  }, [getUser, id]);

  useEffect(() => {
    if (id && currentClasse?.id) {
      listUserLessonsResult(Number(id), currentClasse.id);
    }
  }, [listUserLessonsResult, id, currentClasse?.id]);

  return (
    <>
      <StandardTemplate title="Aluno" showBackButton>
        <LoadingLock isLoading={isLoading} />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={currentTab}
            onChange={handleChangeCurrentTab}
            aria-label="basic tabs example"
          >
            <Tab label="Dados do aluno" {...a11yProps(0)} />
            <Tab label="Progresso do aluno" {...a11yProps(0)} />
          </Tabs>

          <TabPanel value={currentTab} index={0}>
            {student && (
              <StudentForm
                edit
                dataForm={{
                  ...student,
                  birthDate: formatDate(student?.birthDate),
                }}
                onValidateSuccess={handleSave}
                footerActions={<FooterActions onSaveAndBack={() => null} />}
              />
            )}
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            {!studentLessonsResult?.length && (
              <EmptyState message="Esse aluno ainda não fez nenhum exercício ainda." />
            )}
            {studentLessonsResult?.map((studentLesson) => {
              return (
                <Box key={studentLesson.moduleId} sx={{ mt: 2 }}>
                  <Title>{`Módulo: ${studentLesson.moduleName}`}</Title>

                  {studentLesson.pages.map((page) => (
                    <StudentLessonAccordion
                      key={page.pageId}
                      data={page}
                      onPress={handlePress}
                    />
                  ))}
                </Box>
              );
            })}
          </TabPanel>
        </Box>
      </StandardTemplate>
      <DrawerResult ref={drawerResultRef} data={answers} />
    </>
  );
};

export default EditStudent;
