import React, { useCallback, useEffect, useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  TextField,
  Box,
  InputAdornment,
  Divider,
  Grid,
  Button,
  Typography,
} from "@mui/material";

import { FiChevronDown, FiEdit3, FiCheck, FiX, FiPlus } from "react-icons/fi";
import StickyHeaderTable from "../StickyHeaderTable";

import IModuleModel from "@app/features/Module/domain/models/IModuleModel";
import ILessonModel from "@app/features/Lesson/domain/models/ILessonModel";
import { IOrderModel } from "@app/constants/interfaces";

export type onChangeModuleName = {
  moduleId: number;
  value: string;
  onSuccessCallback?: () => void;
};

type ModuleAccordionProps = {
  module: IModuleModel;
  disabled: boolean;
  onExpandModule: (module: IModuleModel, isExpanded: boolean) => void;
  onDeleteLesson: (module: IModuleModel, lesson: ILessonModel) => void;
  onEditLesson: (lesson: ILessonModel) => void;
  onChangeOrderLesson: (moduleId: number, orderEvent: IOrderModel) => void;
  onCreateLesson: (module: IModuleModel) => void;
  onChangeModuleName: ({ moduleId, value }: onChangeModuleName) => void;
};

const ModuleAccordion = ({
  module,
  disabled,
  onCreateLesson,
  onExpandModule,
  onChangeModuleName,
  onChangeOrderLesson,
  onDeleteLesson,
  onEditLesson,
}: ModuleAccordionProps) => {
  const [enableEdit, setEnableEdit] = useState(false);
  const [moduleName, setModuleName] = useState(module.name);

  const handleEnableEdit = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setEnableEdit(true);
    },
    []
  );

  const handleDisableEdit = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setEnableEdit(false);
      setModuleName(module.name);
    },
    [module.name]
  );

  const handleCreateLesson = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onCreateLesson(module);
    },
    [module, onCreateLesson]
  );

  const handleSaveModuleName = () => {
    onChangeModuleName({
      moduleId: module.id,
      value: moduleName,
      onSuccessCallback: () => setEnableEdit(false),
    });
  };

  const handleOrderChange = useCallback(
    (_: ILessonModel[], item: ILessonModel, orderEvent: IOrderModel) => {
      onChangeOrderLesson(item.id, orderEvent);
    },
    [onChangeOrderLesson]
  );

  const handleChangeModuleName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setModuleName(event.target.value);
    },
    []
  );

  const handleChangeExpanded = useCallback(
    (_: React.SyntheticEvent, isExpanded: boolean) => {
      onExpandModule(module, isExpanded);
    },
    [onExpandModule, module]
  );

  const handleDeleteLesson = useCallback(
    (lesson: ILessonModel) => {
      onDeleteLesson(module, lesson);
    },
    [module, onDeleteLesson]
  );

  useEffect(() => {
    setModuleName(module.name);
  }, [module.name]);

  return (
    <Accordion
      disabled={disabled}
      onChange={handleChangeExpanded}
      sx={{
        "& .Mui-focusVisible": { backgroundColor: "#FFF" },
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton size="medium" color="secondary">
            <FiChevronDown />
          </IconButton>
        }
        focusRipple={true}
        focusVisibleClassName="none"
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          "& .MuiAccordionSummary-root": { backgroundColor: "#FFF" },
          "& .Mui-focusVisible": { backgroundColor: "#FFF" },
        }}
      >
        {enableEdit ? (
          <IconButton size="medium" color="primary" onClick={handleDisableEdit}>
            <FiX />
          </IconButton>
        ) : (
          <IconButton size="medium" color="primary" onClick={handleEnableEdit}>
            <FiEdit3 />
          </IconButton>
        )}
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Box ml={2}>
              <TextField
                label="Nome do módulo"
                value={moduleName}
                disabled={!enableEdit}
                variant="standard"
                onChange={handleChangeModuleName}
                onClick={(event) => event.stopPropagation()}
                InputProps={{
                  endAdornment: enableEdit && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSaveModuleName}>
                        <FiCheck />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          {!enableEdit && (
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<FiPlus />}
                onClick={handleCreateLesson}
                size="small"
              >
                Nova aula
              </Button>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <StickyHeaderTable
          identifierColumnKey="id"
          allowReordering
          dialogMessage={{
            title: "Remover usuário?",
            description:
              "Tem certeza que deseja remover esta aula? Todos as perguntas vinculados a ela serão removidos.",
          }}
          count={module.lessons?.length || 0}
          onEdit={onEditLesson}
          onOrderChange={handleOrderChange}
          onDelete={handleDeleteLesson}
          columns={[
            {
              id: "image",
              label: "Capa da aula",
              component: "AVATAR",
            },
            {
              id: "name",
              label: "Nome",
              align: "left",
            },
            {
              id: "publish",
              label: "Publicado?",
              align: "center",
              component: "CUSTOM",
              customComponent: (data) => (
                <Typography variant="overline" display="block" align="center">
                  {data.publish ? "Sim" : "Não"}
                </Typography>
              ),
            },
          ]}
          rows={module.lessons || []}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ModuleAccordion;
