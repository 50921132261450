import IDashboardEntity from "@app/features/Dashboard/data/IDashboardEntity";
import api from "@app/config/api";
import API_SERVICES from "../constants";

export interface IDashboardService {
  getDashboardService: (classeId: number) => Promise<IDashboardEntity>;
}

const getDashboardService = async (
  classeId: number
): Promise<IDashboardEntity> =>
  api
    .get(API_SERVICES.DASHBOARD_SERVICES.GET(classeId), {
      headers: { classeId },
    })
    .then((res) => res.data);

const dashboardService: IDashboardService = {
  getDashboardService,
};

export default dashboardService;
