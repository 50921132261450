import axios, { AxiosHeaders } from "axios";
import { Navigate } from "react-router-dom";

import showNotification from "@app/components/molecules/Toast";

import Storage from "@app/config/storage";
import enviroments from "@app/config/enviroments";
import { store } from "@app/config/store";

const api = axios.create({
  baseURL: enviroments.baseURL,
});

export class Axios {
  static interceptors = {
    request: () => Axios.request,
    response: () => Axios.response,
  };

  static get request() {
    return api.interceptors.request.use(
      async (config) => {
        const accessToken = Storage.get("@SHARPII:accessToken");

        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        } as unknown as AxiosHeaders;
        return { ...config };
      },
      (err) => Promise.reject(err)
    );
  }

  static get response() {
    return api.interceptors.response.use(
      (response) => response,
      async (err): Promise<void> => {
        if (err?.response?.status >= 500) {
          return showNotification("Erro generico", "ERROR");
        }

        if (err?.response?.status === 401) {
          try {
            const accessToken = Storage.get("@SHARPII:accessToken");
            const refreshToken = Storage.get("@SHARPII:refreshToken");
            const body = { accessToken, refreshToken };
            const response = await axios.post(
              `${enviroments.baseURL}/authentication/refresh-token`,
              body
            );
            if (response.status === 200) {
              const { data } = response;
              Storage.set(data.accessToken, "@SHARPII:accessToken");
              Storage.set(data.refreshToken, "@SHARPII:refreshToken");

              const configRetryRequest = {
                ...err.response.config,
                Authorization: `Bearer ${data.accessToken}`,
              };

              return api({ ...configRetryRequest })
                .then((res) => res)
                .catch((error) => error);
            }
          } catch (error) {
            return logOut();
          }
        }
        return Promise.reject(err);
      }
    );
  }
}

const logOut = () => {
  store.dispatch({ type: "RESET_STATE", payload: null });
  Storage.clear();
  Navigate({ to: "/login", replace: true });
};

export default api;
