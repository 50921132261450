import React, { useCallback, useEffect, useState } from "react";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FiCheck, FiPlus, FiRepeat } from "react-icons/fi";
import { Box } from "@mui/material";

import { useModuleRepository } from "@app/features/Module/data/moduleRepository";
import { useListModuleViewModel } from "@app/features/Module/view/List/listModuleViewModel";
import { useLessonRepository } from "@app/features/Lesson/data/lessonRepository";

import moduleService from "@app/services/module";
import lessonService from "@app/services/lesson";

import IModuleModel from "@app/features/Module/domain/models/IModuleModel";
import ILessonModel from "@app/features/Lesson/domain/models/ILessonModel";

import StandardTemplate from "@app/components/templates/Standard";
import LoadingLock from "@app/components/molecules/LoadingLock";
import ModuleAccordion, {
  onChangeModuleName,
} from "@app/components/organisms/ModuleAccordion";
import EmptyState from "@app/components/organisms/EmptyState";

import { useEditLessonViewModel } from "@app/features/Lesson/view/Edit/editLessonViewModel";
import { IOrderModel } from "@app/constants/interfaces";
import { RootState } from "@app/config/store";

const ListModules = () => {
  const navigate = useNavigate();

  const [disableDragAndDrop, setDisableDragAndDrop] = useState(false);

  const moduleRepository = useModuleRepository(moduleService);
  const lessonRepository = useLessonRepository(lessonService);
  const { currentClasse } = useSelector((state: RootState) => state.classe);

  const {
    getModules,
    modules,
    isLoading,
    setModules,
    editModule,
    removeLesson,
    getLessonsFromModule,
  } = useListModuleViewModel(moduleRepository, lessonRepository);

  const { editLesson, isLoading: isLoadingLesson } =
    useEditLessonViewModel(lessonRepository);

  const handleClickCreateButton = useCallback(() => {
    navigate("/admin/modules/create");
  }, [navigate]);

  const handleClickCreateLesson = useCallback(
    (module: IModuleModel) => {
      navigate(`/admin/modules/${module.id}/lessons/create`);
    },
    [navigate]
  );

  const handleClickEditLesson = useCallback(
    (lesson: ILessonModel) => {
      navigate(`/admin/lessons/edit/${lesson.id}`);
    },
    [navigate]
  );

  const onDragEnd = useCallback(
    (event: DropResult) => {
      if (!event.destination) return;
      const result = Array.from(modules);
      const [removed] = result.splice(event.source.index, 1);
      result.splice(event.destination.index, 0, removed);

      setModules([...result]);

      if (currentClasse) {
        editModule({
          id: currentClasse.id,
          reoder: {
            source: event.source.index + 1,
            destination: event.destination.index + 1,
          },
        });
      }
    },
    [currentClasse, modules, setModules, editModule]
  );

  const handleEditModuleName = useCallback(
    (data: onChangeModuleName) => {
      editModule(
        {
          id: data.moduleId,
          name: data.value,
        },
        data.onSuccessCallback
      );
    },
    [editModule]
  );

  const handleExpandModule = useCallback(
    (module: IModuleModel, isExpanded: boolean) => {
      if (isExpanded) {
        getLessonsFromModule(module.id);
      }
    },
    [getLessonsFromModule]
  );

  const handleDeleteLesson = useCallback(
    (module: IModuleModel, lesson: ILessonModel) => {
      if (currentClasse) {
        const { id: classeId } = currentClasse;
        removeLesson(module.id, classeId, lesson.id);
      }
    },
    [removeLesson, currentClasse]
  );

  const handleClickSecondaryButton = useCallback(() => {
    setDisableDragAndDrop((v) => !v);
  }, []);

  const handleChangeOrderLesson = useCallback(
    (lessonId: number, reoderEvent: IOrderModel) => {
      if (currentClasse) {
        const { id: classeId } = currentClasse;
        editLesson(classeId, { id: lessonId, reoder: reoderEvent }, false);
      }
    },
    [currentClasse, editLesson]
  );

  useEffect(() => {
    if (currentClasse) {
      getModules(currentClasse.id);
    }
  }, [currentClasse, getModules]);

  return (
    <>
      <StandardTemplate
        title="Módulos"
        primaryButton={{
          isVisible: true,
          iconButton: <FiPlus />,
          textButton: "Novo",
          onClickButton: handleClickCreateButton,
        }}
        secondaryButton={{
          textButton: disableDragAndDrop
            ? "Salvar ordenação"
            : "Reoedenar módulos",
          isVisible: !!modules.length && modules.length > 1,
          iconButton: disableDragAndDrop ? <FiCheck /> : <FiRepeat />,
          onClickButton: handleClickSecondaryButton,
        }}
      >
        {(isLoading || isLoadingLesson) && (
          <LoadingLock isLoading={isLoading} />
        )}
        {!isLoading && !modules.length && (
          <EmptyState
            message="Você ainda não cadastrou nenhum módulo, seus alunos estão ansiosos pelo seus conteúdos.Para cadastrar o primeiro módulo clique no botão abaixo."
            onClickButton={handleClickCreateButton}
            textButton="Cadastrar módulo"
          />
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {modules.map((module, index) => (
                  <Draggable
                    key={module.id}
                    draggableId={String(module.id)}
                    isDragDisabled={!disableDragAndDrop}
                    index={index}
                  >
                    {(provided) => (
                      <Box mt={2} mb={2}>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ModuleAccordion
                            module={module}
                            disabled={disableDragAndDrop}
                            onChangeOrderLesson={handleChangeOrderLesson}
                            onEditLesson={handleClickEditLesson}
                            onDeleteLesson={handleDeleteLesson}
                            onExpandModule={handleExpandModule}
                            onCreateLesson={handleClickCreateLesson}
                            onChangeModuleName={handleEditModuleName}
                          />
                        </div>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </StandardTemplate>
    </>
  );
};

export default ListModules;
