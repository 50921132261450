import React, { useCallback, useState } from "react";

import { Tab, Tabs } from "@mui/material";

import { useResourceRepository } from "@app/features/Resource/data/resourceRepository";
import resourceService from "@app/services/resource";
import {
  TCreateRosourceViewModel,
  useCreateResourceViewModel,
} from "@app/features/Resource/views/Create/createResourceViewModel";

import ResourceForm, {
  TResourceForm,
} from "@app/features/Resource/views/Form/ResourceForm";
import YoutubeResourceForm, {
  TYoutubeResourceForm,
} from "@app/features/Resource/views/Form/YoutubeResourceForm";

import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";
import TabPanel from "@app/components/templates/TabPanel";
import StandardTemplate from "@app/components/templates/Standard";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreateResourse = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const resourceRepository = useResourceRepository(resourceService);
  const { createResource } = useCreateResourceViewModel(resourceRepository);
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const handleChangeCurrentTab = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: number) => {
      setCurrentTab(value);
    },
    []
  );

  const handleValidateSuccess = useCallback(
    (data: TResourceForm[]) => {
      if (currentCompany) {
        const { id } = currentCompany;
        createResource(data, id);
      }
    },
    [createResource, currentCompany]
  );

  const handleValidateYoutubeFormSuccess = useCallback(
    (data: TYoutubeResourceForm[]) => {
      const dataFormated: TCreateRosourceViewModel[] = data.map(
        (item): TCreateRosourceViewModel => {
          const { languageId, name, url } = item;
          return {
            languageId,
            name,
            url,
          };
        }
      );

      if (currentCompany) {
        const { id } = currentCompany;
        createResource(dataFormated, id);
      }
    },
    [createResource, currentCompany]
  );

  return (
    <StandardTemplate title="Biblioteca" showBackButton>
      <LoadingLock isLoading={false} />
      <Tabs
        value={currentTab}
        onChange={handleChangeCurrentTab}
        aria-label="basic tabs example"
      >
        <Tab label="Upload de arquivos" {...a11yProps(0)} />
        <Tab label="Cadastro de videos do Youtube" {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={currentTab} index={0}>
        <ResourceForm
          onValidateSuccess={handleValidateSuccess}
          footerActions={<FooterActions />}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <YoutubeResourceForm
          onValidateSuccess={handleValidateYoutubeFormSuccess}
          footerActions={<FooterActions />}
        />
      </TabPanel>
    </StandardTemplate>
  );
};

export default CreateResourse;
