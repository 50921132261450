import React, { useCallback, useEffect, useState } from "react";
import {
  CircularProgress,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { FiFileText, FiTrash } from "react-icons/fi";
import { AxiosProgressEvent } from "axios";
import { useSelector } from "react-redux";

import { TUploadPayload } from "@app/components/atoms/Upload";

import { deleteAssetService, uploadAssetService } from "@app/services/upload";

import theme from "@app/config/theme";
import { RootState } from "@app/config/store";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";

type UploadItemProps = {
  index: number;
  file: File;
  onUploadSuccess: (data: TUploadPayload) => void;
  onRemove: (index: number) => void;
};

const UploadItem = ({
  index,
  file,
  onUploadSuccess,
  onRemove,
}: UploadItemProps) => {
  const [progress, setProgress] = useState(0);
  const [loading, seLoading] = useState(false);
  const [upload, setUpload] = useState<TUploadPayload>();

  const { currentCompany } = useSelector((state: RootState) => state.company);

  const uploadFile = useCallback(
    async (dataFile: File) => {
      try {
        seLoading(true);
        const formData = new FormData();
        formData.append(`data`, dataFile, dataFile.name);

        const response = await uploadAssetService(
          formData,
          "RESOURCE",
          currentCompany?.id,
          (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.progress) {
              setProgress(progressEvent.progress * 100);
            }
          }
        );

        let data: TUploadPayload = {
          ...response,
          name: dataFile.name,
          size: dataFile.size,
        };
        setUpload(data);
        onUploadSuccess(data);
        showNotification("Carregamento concluído com sucesso", "SUCCESS");
        seLoading(false);
      } catch (error) {
        seLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [onUploadSuccess, currentCompany?.id]
  );

  const handleRemove = useCallback(async () => {
    if (upload) {
      try {
        await deleteAssetService({ fileKey: upload.fileKey });
        onRemove(index);
        showNotification("Arquivo removido com sucesso.", "SUCCESS");
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    }
  }, [index, onRemove, upload]);

  useEffect(() => {
    if (file && !upload) {
      uploadFile(file);
    }
  }, [file, upload, uploadFile]);

  return (
    <Paper elevation={3} sx={{ p: 1 }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {loading ? (
            <CircularProgress />
          ) : (
            <FiFileText size={25} color={theme.palette.primary.main} />
          )}
          <Typography variant="body1" color={"text.secondary"} align="left">
            {file.name}
          </Typography>
        </Stack>
        <IconButton onClick={handleRemove}>
          <FiTrash size={25} color={theme.palette.error.main} />
        </IconButton>
      </Stack>
      <LinearProgress variant="determinate" value={progress} sx={{ mt: 2 }} />
    </Paper>
  );
};

export default UploadItem;
