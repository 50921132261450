import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";
import { TCreateRosourceViewModel } from "@app/features/Resource/views/Create/createResourceViewModel";

const createResourceUseCase = async (
  repository: Pick<IResourceRepository, "createResources">,
  data: TCreateRosourceViewModel[],
  companyId: number
) => {
  await repository.createResources(companyId, data);
};

export default createResourceUseCase;
