import React, { useCallback, useEffect } from "react";

import { useParams } from "react-router-dom";

import { useResourceRepository } from "@app/features/Resource/data/resourceRepository";
import resourceService from "@app/services/resource";
import { useEditResourceViewModel } from "@app/features/Resource/views/Edit/editResourceViewModel";

import YoutubeResourceForm, {
  TYoutubeResourceForm,
} from "@app/features/Resource/views/Form/YoutubeResourceForm";
import EditResourceForm, {
  TEditResourceForm,
} from "@app/features/Resource/views/Form/EditResourceForm";

import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";
import StandardTemplate from "@app/components/templates/Standard";

import { RESOURCE_TYPE } from "@app/constants/enums";

const EditResourse = () => {
  const { id } = useParams();

  const resourceRepository = useResourceRepository(resourceService);
  const { getResource, editResource, isLoading, resource } =
    useEditResourceViewModel(resourceRepository);

  const handleValidateYoutubeFormSuccess = useCallback(
    (data: TYoutubeResourceForm[]) => {
      if (data.length) {
        const resource = data[0];
        editResource({ ...resource, id: Number(id) });
      }
    },
    [editResource, id]
  );

  const handleValidateEditResourceSuccess = useCallback(
    (data: TEditResourceForm) => {
      editResource({ ...data, id: Number(id) });
    },
    [editResource, id]
  );

  useEffect(() => {
    if (id) {
      getResource(Number(id));
    }
  }, [getResource, id]);

  return (
    <StandardTemplate title="Editar recurso" showBackButton>
      <LoadingLock isLoading={isLoading} />
      {resource?.type === RESOURCE_TYPE.EMBED ? (
        <YoutubeResourceForm
          dataForm={{
            name: resource.name || "",
            url: resource.url || "",
            languageId: resource.languageId || NaN,
          }}
          edit
          onValidateSuccess={handleValidateYoutubeFormSuccess}
          footerActions={<FooterActions />}
        />
      ) : (
        <EditResourceForm
          dataForm={resource}
          onValidateSuccess={handleValidateEditResourceSuccess}
          footerActions={<FooterActions />}
        />
      )}
    </StandardTemplate>
  );
};

export default EditResourse;
