import { ReactNode, useCallback, useEffect, useMemo } from "react";

import { Avatar, Box, Chip, Grid, MenuItem, Paper, Stack } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useLanguageRepository } from "@app/features/Language/data/languageRepository";
import { useResourceRepository } from "@app/features/Resource/data/resourceRepository";

import languageService from "@app/services/language";
import resourceService from "@app/services/resource";

import { useFormResourceViewModel } from "@app/features/Resource/views/Form/formResourcesViewModel";

import UploadMultiple from "@app/components/atoms/UploadMultiple";
import { TUploadPayload } from "@app/components/atoms/Upload";
import SelectUseForm from "@app/components/organisms/SelectUseForm";

export type TResourceForm = {
  name: string;
  url: string;
  fileKey: string;
  size: number;
  languageId: number;
};

type ResourcesFormProps = {
  onValidateSuccess: (data: TResourceForm[]) => void;
  footerActions: ReactNode;
};

const ResourcesForm = ({
  footerActions,
  onValidateSuccess,
}: ResourcesFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        languageId: yup.number().required("Selecione o idioma dos arquivos"),
        resources: yup
          .array()
          .of(
            yup.object().shape({
              name: yup.string(),
              size: yup.number(),
              fileKey: yup.string(),
              url: yup.string(),
            })
          )
          .min(1, "Faça o upload de pelo menos 1 arquivo"),
      })
    ),
    defaultValues: {
      languageId: NaN,
      resources: [] as TResourceForm[],
    },
    mode: "onSubmit",
  });
  const [resources, languageId] = watch(["resources", "languageId"]);

  const languageRepository = useLanguageRepository(languageService);
  const resourceRepository = useResourceRepository(resourceService);

  const { getAvailableLanguages, languages } = useFormResourceViewModel(
    languageRepository,
    resourceRepository
  );

  const handleUploadSuccess = useCallback(
    (data: TUploadPayload) => {
      const { fileKey, name, size, url } = data;

      resources.push({
        fileKey,
        languageId: getValues("languageId"),
        name,
        size,
        url,
      });
    },
    [resources, getValues]
  );

  const handleRemove = useCallback(
    (index: number) => {
      resources.splice(index, 1);
      setValue("resources", resources);
    },
    [resources, setValue]
  );

  const handleClearLanguage = useCallback(() => {
    setValue("languageId", NaN);
  }, [setValue]);

  const onSubmit = useCallback(
    ({ resources }: { resources: TResourceForm[] }) => {
      onValidateSuccess(resources);
    },
    [onValidateSuccess]
  );

  const languageData = useMemo(() => {
    const data = languages.find((l) => l.id === languageId);

    return data;
  }, [languageId, languages]);

  useEffect(() => {
    getAvailableLanguages();
  }, [getAvailableLanguages]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ p: 2 }}>
        <Grid
          container
          justifyContent="center"
          flexDirection="column"
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={12}
            justifyContent="center"
            alignItems="center"
          >
            {!!languageId ? (
              <Stack direction="row" spacing={1} justifyContent="center">
                <Chip
                  avatar={<Avatar src={languageData?.image} />}
                  label={languageData?.name}
                  onDelete={handleClearLanguage}
                />
              </Stack>
            ) : (
              <SelectUseForm
                error={!!errors.languageId}
                helperText={errors.languageId?.message}
                control={control}
                label="Selecione o idioma para os materiais"
                defaultValue={String(languageId)}
                {...register("languageId")}
              >
                <MenuItem value="">
                  Selecione o idioma para os materiais
                </MenuItem>
                {languages.map((language) => (
                  <MenuItem value={language.id} key={language.key}>
                    {language.name}
                  </MenuItem>
                ))}
              </SelectUseForm>
            )}
          </Grid>

          {!!languageId && (
            <Grid
              item
              xs={12}
              sm={12}
              justifyContent="center"
              alignItems="center"
            >
              <UploadMultiple
                accept={{ all: true }}
                onRemove={handleRemove}
                onUploadSuccess={handleUploadSuccess}
                error={!!errors.resources}
                errorMessage={errors.resources?.message}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      <Box sx={{ mt: 10 }}>{footerActions}</Box>
    </form>
  );
};

export default ResourcesForm;
