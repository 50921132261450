import React, { ReactNode } from "react";

import Box from "@mui/material/Box";

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
  style?: React.CSSProperties;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, style } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
    >
      <Box>{children}</Box>
    </div>
  );
};

export default TabPanel;
