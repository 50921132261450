import { useCallback } from "react";

import IResourceEntity from "@app/features/Resource/data/IResourceEntity";
import IYoutubeInfoEntity from "@app/features/Resource/data/IYoutubeInfoEntity";

import {
  IResourceService,
  TListResourcesSearchParams,
} from "@app/services/resource";

import { TCreateResourceRemoteData } from "@app/services/resource/remote-types/TCreateResourceRemoteData";
import { TEditResourceRemoteData } from "@app/services/resource/remote-types/TEditResourceRemoteData";

import IPaginationModel, { IPageModel } from "@app/constants/interfaces";

export interface IResourceRepository {
  listResources(
    companyId: number,
    pageConfig: IPageModel,
    filterParams?: TListResourcesSearchParams
  ): Promise<IPaginationModel<IResourceEntity>>;
  getResource(resourceId: number): Promise<IResourceEntity>;
  createResources(
    companyId: number,
    data: TCreateResourceRemoteData[]
  ): Promise<void>;
  deleteResource(resourceId: number): Promise<void>;
  getYoutubeInfo(url: string): Promise<IYoutubeInfoEntity>;
  editResource(data: TEditResourceRemoteData): Promise<void>;
}

const useResourceRepository = (
  resourceService: IResourceService
): IResourceRepository => {
  const listResources = useCallback(
    async (
      companyId: number,
      pageConfig: IPageModel,
      filterParams?: TListResourcesSearchParams
    ): Promise<IPaginationModel<IResourceEntity>> => {
      const resources = await resourceService.listResourcesService(
        companyId,
        pageConfig,
        filterParams
      );

      return resources;
    },
    [resourceService]
  );

  const getResource = useCallback(
    async (resourceId: number): Promise<IResourceEntity> => {
      const data = await resourceService.getResourceService(resourceId);

      return data;
    },
    [resourceService]
  );

  const editResource = useCallback(
    async (data: TEditResourceRemoteData): Promise<void> => {
      await resourceService.editResourceService(data);
    },
    [resourceService]
  );

  const createResources = useCallback(
    async (
      companyId: number,
      data: TCreateResourceRemoteData[]
    ): Promise<void> => {
      await resourceService.createResourcesService(data, companyId);
    },
    [resourceService]
  );

  const deleteResource = useCallback(
    async (resourceId: number): Promise<void> => {
      await resourceService.deleteResourceService(resourceId);
    },
    [resourceService]
  );

  const getYoutubeInfo = useCallback(
    async (videourl: string): Promise<IYoutubeInfoEntity> => {
      const data = await resourceService.getYoutubeInfoService(videourl);
      return data;
    },
    [resourceService]
  );

  return {
    listResources,
    createResources,
    deleteResource,
    getResource,
    getYoutubeInfo,
    editResource,
  };
};

export { useResourceRepository };
