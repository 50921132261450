import pageNotFound from "./lotties/pageNotFound.json";
import launchCreate from "./lotties/launchCreate.json";
import success from "./lotties/success.json";
import congratulation from "./lotties/congratulation.json";
import emptyState from "./lotties/empty-state.json";
import invite from "./lotties/invite.json";
import dashboard from "./lotties/dashboard.json";

const logoBackground = require("./images/logo-background.png");
const logoWhite = require("./images/logo-white.png");
const pattern = require("./images/bg-pattern.png");
const multipleChoice = require("./images/questions-type/multiple-choice.png");
const order = require("./images/questions-type/order.png");
const speaking = require("./images/questions-type/speaking.png");
const traditional = require("./images/questions-type/traditional.png");

const lotties = {
  pageNotFound,
  launchCreate,
  success,
  congratulation,
  emptyState,
  invite,
  dashboard,
};

const images = {
  logoBackground,
  logoWhite,
  pattern,
  multipleChoice,
  order,
  speaking,
  traditional,
};

export { lotties, images };
