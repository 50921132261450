import { ILessonRepository } from "@app/features/Lesson/data/lessonRepository";
import { TEditLessonViewModel } from "@app/features/Lesson/view/Edit/editLessonViewModel";

const editLessonUseCase = async (
  repository: Pick<ILessonRepository, "editLesson">,
  classeId: number,
  data: TEditLessonViewModel
) => {
  await repository.editLesson(classeId, data);
};

export default editLessonUseCase;
