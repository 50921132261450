import { useCallback, useState } from "react";

import createResourceUseCase from "@app/features/Resource/domain/use-cases/createResourceUseCase";

import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";
import { useNavigate } from "react-router-dom";

export type TCreateRosourceViewModel = {
  name: string;
  url: string;
  fileKey?: string;
  size?: number;
  languageId: number;
};

const useCreateResourceViewModel = (repository: IResourceRepository) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const createResource = useCallback(
    async (data: TCreateRosourceViewModel[], companyId: number) => {
      try {
        setLoading(true);

        await createResourceUseCase(
          {
            createResources: repository.createResources,
          },
          data,
          companyId
        );
        setLoading(false);
        navigate(-1);
        showNotification("Recurso criado com sucesso", "SUCCESS");
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.createResources, navigate]
  );

  return { isLoading, createResource };
};

export { useCreateResourceViewModel };
