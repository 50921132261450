import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo } from "react";

import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useLanguageRepository } from "@app/features/Language/data/languageRepository";
import { useFormResourceViewModel } from "@app/features/Resource/views/Form/formResourcesViewModel";

import languageService from "@app/services/language";

import SelectUseForm from "@app/components/organisms/SelectUseForm";
import { regex } from "@app/constants";
import { FiPlus } from "react-icons/fi";
import { useResourceRepository } from "../../data/resourceRepository";
import resourceService from "@app/services/resource";

export type TYoutubeResourceForm = {
  name: string;
  url: string;
  languageId: number;
};

type ResourcesFormProps = {
  edit?: boolean;
  dataForm?: TYoutubeResourceForm;
  onValidateSuccess: (data: TYoutubeResourceForm[]) => void;
  footerActions: ReactNode;
};

const ResourcesForm = ({
  edit,
  dataForm,
  onValidateSuccess,
  footerActions,
}: ResourcesFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        languageId: yup.number().required("Selecione o idioma dos arquivos"),
        resources: yup.array().of(
          yup.object().shape({
            name: yup.string().required("Coloque um nome para o video"),
            url: yup.string().url("Coloque uma url válida"),
          })
        ),
      })
    ),
    defaultValues: {
      languageId: NaN,
      resources: [] as TYoutubeResourceForm[],
    },
    mode: "onSubmit",
  });
  const [resources, languageId] = watch(["resources", "languageId"]);

  const languageRepository = useLanguageRepository(languageService);
  const resourceRepository = useResourceRepository(resourceService);

  const { getAvailableLanguages, getYoutubeInfo, languages } =
    useFormResourceViewModel(languageRepository, resourceRepository);

  const handleClearLanguage = useCallback(() => {
    setValue("languageId", NaN);
  }, [setValue]);

  const onSubmit = useCallback(
    ({ resources }: { resources: TYoutubeResourceForm[] }) => {
      onValidateSuccess(resources);
    },
    [onValidateSuccess]
  );

  const handleGetYoutubeId = useCallback(
    (index: number) => {
      var match = resources?.[index].url.match(regex.youtubeUrl);
      if (match && match[2].length === 11) {
        return match[2];
      }
    },
    [resources]
  );

  const handleAddVideo = useCallback(() => {
    resources.push({ languageId, name: "", url: "" });
    setValue("resources", resources);
  }, [resources, setValue, languageId]);

  const handleChangeVideoUrl = useCallback(
    async (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number
    ) => {
      const value = event.target.value;
      setValue(`resources.${index}.url`, value);

      const info = await getYoutubeInfo(value);
      if (info) {
        setValue(`resources.${index}.name`, info?.title);
      }
    },
    [setValue, getYoutubeInfo]
  );

  const languageData = useMemo(() => {
    const data = languages.find((l) => l.id === languageId);

    return data;
  }, [languageId, languages]);

  useEffect(() => {
    if (!edit) {
      if (languageId) {
        let data: TYoutubeResourceForm = { languageId, name: "", url: "" };
        setValue("resources", [data]);
      } else {
        setValue("resources", []);
      }
    }
  }, [languageId, edit, setValue]);

  useEffect(() => {
    getAvailableLanguages();
  }, [getAvailableLanguages]);

  useEffect(() => {
    if (dataForm) {
      const { languageId, name, url } = dataForm;

      reset({
        languageId,
        resources: [{ name, url, languageId }],
      });
    }
  }, [dataForm, reset]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          justifyContent="center"
          flexDirection="column"
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={12}
            justifyContent="center"
            alignItems="center"
          >
            {!!languageId ? (
              <Stack direction="row" spacing={1} justifyContent="center">
                <Chip
                  avatar={<Avatar src={languageData?.image} />}
                  label={languageData?.name}
                  onDelete={handleClearLanguage}
                />
              </Stack>
            ) : (
              <SelectUseForm
                error={!!errors.languageId}
                helperText={errors.languageId?.message}
                control={control}
                label="Selecione o idioma para os materiais"
                defaultValue={String(languageId)}
                {...register("languageId")}
              >
                <MenuItem value="">
                  Selecione o idioma para os materiais
                </MenuItem>
                {languages.map((language) => (
                  <MenuItem value={language.id} key={language.key}>
                    {language.name}
                  </MenuItem>
                ))}
              </SelectUseForm>
            )}
          </Grid>

          {!!languageId &&
            resources.map((resource, index, array) => (
              <>
                <Grid
                  key={index}
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  {resource.url && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <iframe
                        width="350"
                        height="155"
                        src={`https://www.youtube.com/embed/${handleGetYoutubeId(
                          index
                        )}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        style={{
                          borderRadius: 5,
                          borderWidth: 0,
                        }}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <TextField
                      id="url"
                      label="Url de video youtube"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      required
                      value={resources[index].url}
                      defaultValue={resources[index].url}
                      onChange={(evt) => handleChangeVideoUrl(evt, index)}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.resources?.[index]?.url}
                      helperText={
                        !!errors.resources?.[index]?.url &&
                        errors.resources?.[index]?.url?.message
                      }
                    />
                  </Grid>
                  {resource.url && (
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="name"
                        label="Nome do video"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.resources?.[index]?.name}
                        helperText={
                          !!errors.resources?.[index]?.name &&
                          errors.resources?.[index]?.name?.message
                        }
                        {...register(`resources.${index}.name`)}
                      />
                    </Grid>
                  )}
                </Grid>
                {array.length > 1 && index < array.length - 1 && (
                  <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>
                )}
              </>
            ))}
        </Grid>
        {!!languageId && !edit && (
          <Button
            variant="text"
            startIcon={<FiPlus />}
            onClick={handleAddVideo}
          >
            Adicionar Video
          </Button>
        )}
      </Paper>
      <Box sx={{ mt: 10 }}>{footerActions}</Box>
    </form>
  );
};

export default ResourcesForm;
