import { useCallback } from "react";

import IModuleEntity from "@app/features/Module/data/IModuleEntity";
import ILessonEntity from "@app/features/Lesson/data/ILessonEntity";

import { TCreateModuleRemoteData } from "@app/services/module/remote-types/TCreateModuleRemoteData";
import { TEditModuleRemoteData } from "@app/services/module/remote-types/TEditModuleRemoteData";

import { IModuleService } from "@app/services/module";

export interface IModuleRepository {
  createModule(data: TCreateModuleRemoteData): Promise<void>;
  editModule(data: TEditModuleRemoteData): Promise<void>;
  getModules(classeId: number): Promise<IModuleEntity[]>;
  getLessonsFromModule(moduleId: number): Promise<ILessonEntity[]>;
}

const useModuleRepository = (
  moduleService: IModuleService
): IModuleRepository => {
  const createModule = useCallback(
    async (data: TCreateModuleRemoteData): Promise<void> => {
      await moduleService.createModuleService(data);
    },
    [moduleService]
  );

  const editModule = useCallback(
    async (data: TEditModuleRemoteData): Promise<void> => {
      await moduleService.editModuleService(data);
    },
    [moduleService]
  );

  const getModules = useCallback(
    async (classeId: number): Promise<IModuleEntity[]> => {
      const modules = await moduleService.getModulesService(classeId);
      return modules;
    },
    [moduleService]
  );

  const getLessonsFromModule = useCallback(
    async (moduleId: number): Promise<ILessonEntity[]> => {
      const lessons = await moduleService.getLessonsFromModule(moduleId);
      return lessons;
    },
    [moduleService]
  );

  return {
    createModule,
    editModule,
    getModules,
    getLessonsFromModule,
  };
};

export { useModuleRepository };
