import { useCallback, useState } from "react";

import listModulesUseCase from "@app/features/Module/domain/use-cases/listModulesUseCase";
import editModuleUseCase from "@app/features/Module/domain/use-cases/editModuleUseCase";
import getLessonsFromModuleUseCase from "@app/features/Module/domain/use-cases/getLessonsFromModuleUseCase";
import removeLessonUseCase from "@app/features/Lesson/domain/use-cases/removeLessonUseCase";

import { IModuleRepository } from "@app/features/Module/data/moduleRepository";
import { ILessonRepository } from "@app/features/Lesson/data/lessonRepository";

import IModuleModel from "@app/features/Module/domain/models/IModuleModel";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";

export type TEditModuleViewModel = {
  id: number;
  name?: string;
  reoder?: {
    source: number;
    destination: number;
  };
};

const useListModuleViewModel = (
  moduleRepository: IModuleRepository,
  lessonRepository: ILessonRepository
) => {
  const [isLoading, setLoading] = useState(false);
  const [modules, setModules] = useState<IModuleModel[]>([]);

  const getModules = useCallback(
    async (classeId: number) => {
      try {
        setLoading(true);

        const data = await listModulesUseCase(
          {
            getModules: moduleRepository.getModules,
          },
          classeId
        );
        setLoading(false);
        setModules(data);
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    },
    [moduleRepository.getModules]
  );

  const getLessonsFromModule = useCallback(
    async (moduleId: number) => {
      try {
        setLoading(true);

        const data = await getLessonsFromModuleUseCase(
          {
            getLessonsFromModule: moduleRepository.getLessonsFromModule,
          },
          moduleId
        );
        setLoading(false);
        setModules((currentModules) => {
          let moduleIndex: number = -1;

          const module = currentModules.find((module, index) => {
            if (module.id === moduleId) {
              moduleIndex = index;
              return module;
            }
            return null;
          });

          if (module) {
            module.lessons = data;
            currentModules[moduleIndex] = module;
          }

          return [...currentModules];
        });
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [moduleRepository.getLessonsFromModule]
  );

  const editModule = useCallback(
    async (data: TEditModuleViewModel, successCallback?: () => void) => {
      try {
        setLoading(true);

        await editModuleUseCase(
          {
            editModule: moduleRepository.editModule,
          },
          data
        );
        setLoading(false);
        showNotification("Módulo alterado com sucesso", "SUCCESS");
        if (successCallback) successCallback();
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [moduleRepository.editModule]
  );

  const removeLesson = useCallback(
    async (moduleId: number, classeId: number, lessonId: number) => {
      try {
        setLoading(true);

        await removeLessonUseCase(
          {
            removeLesson: lessonRepository.removeLesson,
          },
          classeId,
          lessonId
        );
        setModules((currentModules) => {
          let moduleIndex: number = -1;

          const module = currentModules.find((module, index) => {
            if (module.id === moduleId) {
              moduleIndex = index;
              return module;
            }
            return null;
          });

          if (module) {
            const lessons = module.lessons?.filter((l) => l.id !== lessonId);
            module.lessons = lessons;
            currentModules[moduleIndex] = module;
          }

          return [...currentModules];
        });
        setLoading(false);
        showNotification("Aula removida com sucesso", "SUCCESS");
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [lessonRepository.removeLesson]
  );

  return {
    isLoading,
    modules,
    getModules,
    setModules,
    editModule,
    removeLesson,
    getLessonsFromModule,
  };
};

export { useListModuleViewModel };
