import { ILessonRepository } from "@app/features/Lesson/data/lessonRepository";

import ILessonEntity from "@app/features/Lesson/data/ILessonEntity";
import ILessonModel from "@app/features/Lesson/domain/models/ILessonModel";
import IQuestionModel, {
  IGenericAnswerModel,
  IMultipleChoiceModel,
} from "@app/features/Question/domain/models/IQuestionModel";

import { QUESTION_TYPE } from "@app/constants/enums";

const getLessonUseCase = async (
  repository: Pick<ILessonRepository, "getLesson">,
  classeId: number,
  lessonId: number
): Promise<{ lesson: ILessonModel; questions: IQuestionModel[] }> => {
  const result = await repository.getLesson(classeId, lessonId);

  const { lesson, questions } = mapToDomain(result);

  return { lesson, questions };
};

const mapToDomain = (
  entity: ILessonEntity
): { lesson: ILessonModel; questions: IQuestionModel[] } => {
  const {
    id,
    name,
    publish,
    description,
    image,
    complementaryMaterials,
    resourceMedia,
    questions,
    canRetake,
    minScore,
    minScoreValue,
    randomAnswers,
    randomQuestions,
    showResult,
    releaseAfterDay,
    releaseDate,
    releaseDateType,
  } = entity;

  const questionsDomain = questions.map((question): IQuestionModel => {
    const {
      id,
      description: questionDescription,
      questionType,
      data,
      resourceMedia,
    } = question;

    switch (questionType) {
      case QUESTION_TYPE.ORDERING:
      case QUESTION_TYPE.SPEAKING:
      case QUESTION_TYPE.TEXT:
        return {
          id,
          description: questionDescription,
          data: data as IGenericAnswerModel,
          questionType,
          resourceMedia,
        };

      default:
        const answersDomain = (data as IMultipleChoiceModel[]).map(
          (option): IMultipleChoiceModel => option
        );

        return {
          id,
          description: questionDescription,
          data: { answers: answersDomain },
          questionType,
          resourceMedia,
        };
    }
  });

  const lesson: ILessonModel = {
    id,
    name,
    publish,
    description,
    image,
    complementaryMaterials,
    resourceMedia,
    canRetake,
    minScore,
    minScoreValue,
    randomAnswers,
    randomQuestions,
    showResult,
    releaseAfterDay,
    releaseDate: releaseDate,
    releaseDateType,
  };

  return { lesson, questions: questionsDomain };
};

export default getLessonUseCase;
