const isValidDateBirth = (dateBirth: string): boolean => {
  const [day, month, year] = dateBirth.split("/");
  if (day.includes("_") && month.includes("_") && year.includes("_")) {
    return false;
  }

  const date = new Date(`${month}/${day}/${year}`);
  if (date.toString() === "Invalid Date") {
    return false;
  }

  return true;
};

const transformDate = (dateBirth: string): string => {
  const [day, month, year] = dateBirth.split("/");
  return `${year}/${month}/${day}`;
};

const formatBytes = (bytes: number, decimals?: number) => {
  if (bytes === 0) return "0 Bytes";
  if (typeof bytes !== "number") return "-";
  var k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const asyncEvery = async <T>(
  arr: T[],
  checkFunction: (item: T, index: number) => Promise<boolean>
) => {
  for (let [index, item] of arr.entries()) {
    const result = await checkFunction(item, index);

    if (!result) return false;
  }
  return true;
};

const formatCnpjCpf = (value: string) => {
  const cnpjCpf = value.replace(/\D/g, "");

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }

  return cnpjCpf.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
};

function isValidCPF(cpf: string) {
  if (typeof cpf !== "string") return false;

  cpf = cpf.trim();

  cpf = cpf.replace(/\./g, "");
  cpf = cpf.replace("-", "");

  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  ) {
    return false;
  }
  var soma = 0;
  var resto;
  for (var i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
}

const isValidCNPJ = (cnpj: string): boolean => {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj.length !== 14) return false;

  let totalLength = cnpj.length - 2;
  let cnpjWithoutDigits = cnpj.substring(0, totalLength);
  let verificationDigits = cnpj.substring(totalLength);
  let sum = 0;
  let position = totalLength - 7;

  for (let i = totalLength; i >= 1; i--) {
    sum += parseInt(cnpjWithoutDigits.charAt(totalLength - i), 10) * position--;
    if (position < 2) position = 9;
  }

  const result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(verificationDigits.charAt(0), 10)) return false;

  totalLength = totalLength + 1;
  cnpjWithoutDigits = cnpj.substring(0, totalLength);
  sum = 0;
  position = totalLength - 7;

  for (let i = totalLength; i >= 1; i--) {
    sum += parseInt(cnpjWithoutDigits.charAt(totalLength - i), 10) * position--;
    if (position < 2) position = 9;
  }

  const secondResult = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (secondResult !== parseInt(verificationDigits.charAt(1), 10)) return false;

  return true;
};

const Util = {
  isValidCNPJ,
  isValidCPF,
  formatCnpjCpf,
  isValidDateBirth,
  transformDate,
  asyncEvery,
  formatBytes,
};

export default Util;
